"use client";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { useLayoutEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { BiLogoPlayStore, BiX } from "react-icons/bi";
import { FaAppStore } from "react-icons/fa";
import { useTranslations } from "next-intl";
import Link from "next/link";

export default function MobileAppReleaseAnnouncementModal() {
    const [doesExist, setDoesExist] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const m = useTranslations("MobileAppAnnouncementSection");

    useLayoutEffect(() => {
        if (!localStorage.getItem("mobileAnnouncementDismissed")) {
            localStorage.setItem("mobileAnnouncementDismissed", true);
            setDoesExist(true);
        }
    }, []);

    return doesExist ? <>
        <div role="button" onClick={() => setIsOpen(true)} className='bg-[#006038] py-4 text-center max-sm:text-sm align-middle content-center'>
            <span className="text-balance">{m("header")}</span>
            <BiX role="button"
                className="float-right size-5 mr-2"
                onClick={() => {
                    setDoesExist(false);
                    localStorage.setItem("mobileAnnouncementDismissed", true);
                }}
            />
        </div>
        <Modal placement="center" isOpen={isOpen} onOpenChange={setIsOpen}>
            <ModalContent className="z-[999]">
                <ModalHeader>{m("title")}</ModalHeader>
                <ModalBody>
                    <p>{m.rich('text', {
                        break: _ => <br />
                    })}</p>
                </ModalBody>
                <ModalFooter className="gap-x-4">
                    <Button as={Link} rel="nofollow" href="https://play.google.com/store/apps/details?id=com.pellaglobalnet.pellaglobalmobileapp&hl=tr" target="_blank" className="w-full sm:w-auto flex items-center justify-center space-x-2 bg-white text-[#006038] hover:bg-gray-100">
                        <BiLogoPlayStore className="w-5 h-5 max-sm:hidden" />
                        <span className="max-sm:text-xs">{m("android")}</span>
                    </Button>
                    <Button
                        variant="outline"
                        className="w-full sm:w-auto flex items-center justify-center space-x-2 border-white text-white"
                        disabled
                    >
                        <FaAppStore className="w-5 h-5 max-sm:hidden" />
                        <span>{m("ios")}</span>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </> : null;
}