"use client";

import { useState, useEffect, useRef } from 'react';
import { Navbar, NavbarContent, NavbarMenu, NavbarMenuToggle, NavbarBrand, NavbarItem, NavbarMenuItem } from "@nextui-org/navbar";
import { DropdownItem, DropdownTrigger, Dropdown, DropdownMenu } from "@nextui-org/dropdown";
import Link from 'next/link';
import { Button } from "@nextui-org/button";
import { LogoWithOutIcon } from "./icons";
import { Divider } from "@nextui-org/divider";
import { ChevronDown, AboutIcon, ContactIcon, TeamIcon, CareerIcon, RocketIcon } from "./icons";
import { IoMegaphone } from "react-icons/io5";
import { TbDeviceMobileCode, TbWorldSearch, TbDeviceMobileHeart, TbNetwork } from "react-icons/tb";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { TiShoppingCart } from "react-icons/ti";
import { FaCode, FaSearch, FaHandshake } from "react-icons/fa";
import { LuTextSelect } from "react-icons/lu";
import { FaPeopleArrows } from "react-icons/fa6";
import { GiWorld } from "react-icons/gi";
import { GrCpu } from "react-icons/gr";
import { RiVideoOnFill, RiAdvertisementFill } from "react-icons/ri";
import { PiConfetti } from 'react-icons/pi';
import { AiFillHome } from "react-icons/ai";
import { useLocale } from 'next-intl';
import LanguageSwitcher from "./language_switcher";
import { usePathname } from 'next/navigation';
import MobileAppReleaseAnnouncementModal from "@/components/mobile_header";

export default function NavigationBar() {
  const navbarToggleRef = useRef();
  const pathname = usePathname();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const handleSideMenu = () => {
    if (isSideMenuOpen && navbarToggleRef.current) {
      navbarToggleRef.current.click();
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsSideMenuOpen(false);
      if (isSideMenuOpen && navbarToggleRef.current) {
        navbarToggleRef.current.click();
      }
    };
    const handlePathnameChange = () => {
      handleRouteChange();
    };
    handlePathnameChange();
  }, [pathname]);

  const locale = useLocale();
  const loadFAQData = (locale) => {
    try {
      const context = require.context('../messages', true, /\.json$/);
      const data = context(`./${locale}/Menu.json`);
      return data;
    } catch (error) {
      return null;
    }
  };

  const data = loadFAQData(locale);

  const icons = {
    chevron: <ChevronDown fill="currentColor" size={16} />,
    about_us: <AboutIcon className="text-black dark:text-white me-2" fill="currentColor" size={25} />,
    contact_us: <ContactIcon className="text-black dark:text-white me-2" fill="currentColor" size={25} />,
    our_team: <TeamIcon className="text-black dark:text-white me-2" fill="currentColor" size={25} />,
    career: <CareerIcon className="text-black dark:text-white me-2" fill="currentColor" size={25} />,
    seo_analysis_tool: <RocketIcon className="text-black dark:text-white me-2" fill="currentColor" size={25} />,
    pr: <IoMegaphone className='text-white size-[25px] me-2' />,
    mobile: <TbDeviceMobileCode className='text-white size-[25px] me-2' />,
    video: <RiVideoOnFill className='text-white size-[25px] me-2' />,
    code: <FaCode className='text-white size-[25px] me-2' />,
    seo: <TbWorldSearch className='text-white size-[25px] me-2' />,
    commerce: <TiShoppingCart className='text-white size-[25px] me-2' />,
    home: <AiFillHome className='text-white size-[25px] me-2' />,
    event: <PiConfetti className='text-white size-[25px] me-2' />,
    hierarchy: <VscTypeHierarchySub className='text-white size-[25px] me-2' />,
    ad: <RiAdvertisementFill className='text-white size-[25px] me-2' />,
    social: <TbDeviceMobileHeart className='text-white size-[25px] me-2' />,
    search: <FaSearch className='text-white size-[25px] me-2' />,
    network: <TbNetwork className='text-white size-[25px] me-2' />,
    ai: <GrCpu className='text-white size-[25px] me-2' />,
    text: <LuTextSelect className='text-white size-[25px] me-2' />,
    people: <FaPeopleArrows className='text-white size-[25px] me-2' />,
    world: <GiWorld className='text-white size-[25px] me-2' />,
    solution: <FaHandshake className='text-white size-[25px] me-2' />
  };

  return (
    <>
      <MobileAppReleaseAnnouncementModal />
      {/** Mobile Menu */}
      <Navbar className="flex md:hidden justify-between items-center z-[950]" position="sticky">
        <NavbarContent className="md:hidden basis-1" justify="start">
          <LanguageSwitcher />
        </NavbarContent>

        <NavbarBrand>
          <Link href={locale === "en" ? "/" : "/tr"} title="PellaGlobal.net - Digital Marketing Agency in Antalya" onClick={handleSideMenu}>
            <LogoWithOutIcon width={150} className="mt-2" />
          </Link>
        </NavbarBrand>

        <NavbarContent as="div" className="md:hidden basis-1" justify="end">
          <NavbarMenuToggle ref={navbarToggleRef} onChange={(isSelected) => setIsSideMenuOpen(isSelected)} />
        </NavbarContent>

        {/* Mobile Menu */}
        <NavbarMenu>
          <div className="mx-4 mt-2 flex flex-col gap-2 space-y-4">
            {data.Menu.map((item, index) => (
              item.dropdown && item.dropdown.length > 0 ? (
                <details className="group [&_summary::-webkit-details-marker]:hidden" key={item.label}>
                  <summary>
                    <NavbarMenuItem className="flex justify-between">
                      <div color="foreground">{item.label}</div>
                      <span className="shrink-0 transition duration-300 group-open:-rotate-180 mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </span>
                    </NavbarMenuItem>
                  </summary>
                  <ul className="ps-6 py-3">
                    {item.dropdown.map((dropdownItem, innerIndex) => (
                      <li key={dropdownItem.key}>
                        <Link href={dropdownItem.href} title={dropdownItem.title} className="opacity-70 hover:opacity-100 flex flex-row flex-wrap" onClick={handleSideMenu}>
                          <strong className="text-[16px] w-full block">{dropdownItem.title}</strong>
                          <p className="text-[14px] leading-[18px] text-wrap mb-4">{dropdownItem.description}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </details>
              ) : (
                <NavbarMenuItem key={item.label}>
                  <Link size="lg" href={item.href} title={item.title} onClick={handleSideMenu}>
                    {item.label}
                  </Link>
                </NavbarMenuItem>
              )
            ))}
            <NavbarMenuItem>
              <Link href={data.GetQuote.href} size="lg" title={data.GetQuote.title} onClick={handleSideMenu}>
                {data.GetQuote.title}
              </Link>
            </NavbarMenuItem>
          </div>
          <Divider className="my-4" />
        </NavbarMenu>
      </Navbar>

      {/** Main Menu */}
      <Navbar className="hidden md:flex gap-4 z-[950]" maxWidth="2xl" position="sticky">
        <NavbarBrand>
          <Link href={locale === "en" ? "/" : "/tr"} title="PellaGlobal.net - Digital Marketing Agency in Antalya">
            <LogoWithOutIcon width={200} />
          </Link>
        </NavbarBrand>

        <NavbarContent className="hidden md:flex gap-10" justify="center">
          {data.Menu.map((item, index) => (
            item.dropdown && item.dropdown.length > 0 ? (
              <Dropdown
                key={item.label}
                showArrow
                classNames={{
                  base: "before:bg-default-200",
                  content: "py-1 px-1 border border-default-200 bg-gradient-to-br from-white to-default-200 dark:from-default-50 dark:to-black",
                }}
              >
                <NavbarItem>
                  <DropdownTrigger>
                    <div title={item.label} className="gap-x-2 cursor-pointer data-[hover=true]:!bg-transparent text-[15px] font-normal dark:font-extralight opacity-70 hover:opacity-100 light:opacity-85 flex justify-between items-center" radius="sm" variant="light">{item.label} <div>{icons.chevron}</div></div>
                  </DropdownTrigger>
                </NavbarItem>
                <DropdownMenu aria-label="" className={`${item.hasOwnProperty("baseClassName") ? item.baseClassName : "w-[340px]"}`} classNames={{ list: item.hasOwnProperty("listClassName") ? item.listClassName : "" }} itemClasses={{ base: "gap-3" }}>
                  {item.dropdown.map((dropdownItem, innerIndex) => (
                    <DropdownItem as={Link} key={dropdownItem.key} className='!h-min' description="" href={dropdownItem.href} startContent={icons[dropdownItem.icon]} aria-label={dropdownItem.title}>
                      <div>
                        {dropdownItem.title}<br />
                        <p className="text-[#959595] text-wrap text-[12px] leading-4 group-hover:text-white">{dropdownItem.description}</p>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            ) : (
              <NavbarItem key={item.label}>
                <Link className="text-[15px] opacity-70 hover:opacity-100" color="foreground" href={item.href} title={item.title}>
                  {item.label}
                </Link>
              </NavbarItem>
            )
          ))}
        </NavbarContent>

        <NavbarContent className="hidden md:flex flex-1 w-full basis-1/5 sm:basis-full" justify="end">
          <NavbarItem className="flex gap-4">
            <LanguageSwitcher />
          </NavbarItem>
          <NavbarItem className="hidden lg:flex">
            <Button as={Link} className="text-sm font-normal !text-white !bg-[#006038] dark:text-black dark:bg-white rounded-full px-6" href={data.GetQuote.href} title={data.GetQuote.title}>{data.GetQuote.title}</Button>
          </NavbarItem>
        </NavbarContent>
      </Navbar>
    </>
  );
};